<template>
  <div class="token-why-latin-america bg--color-02">
    <div class="token-why-latin-america__wrapper container-wrapper">
      <div class="container-new">
        <div class="token-why-latin-america__grid">
          <div
            class="token-why-latin-america__title txt--32px txt--40px--m txt--height-40px txt--height-52px--m txt--color-04 txt--bold"
          >
            {{ $t('welcome.latinAmerica.title') }}
          </div>

          <div class="token-why-latin-america__map">
            <!-- <nuxt-picture
              src="/img/token/latin-america/map.png"
              sizes="xs: 600px sm:600px md:680px md:900px lg:1240px"
              loading="lazy"
              width="300"
              height="300"
              alt="latin-america"
            /> -->
            <img
              src="@/assets/img/welcome/token/latin-america/map.png"
              loading="lazy"
              width="300"
              height="300"
              alt="latin-america"
            >
          </div>

          <div class="token-why-latin-america__items">
            <div
              v-for="(item, index) in items"
              :key="index"
              class="token-why-latin-america__item"
            >
              <div class="token-why-latin-america__flag-container bg--color-01">
                <img
                  :src="item.flag"
                  width="30"
                  height="30"
                  loading="lazy"
                  :alt="item.key"
                >
              </div>

              <span
                class="txt txt--16px txt--height-20px txt--22px--m txt--height-30px--m txt--color-04"
                v-html="item.text"
              />
            </div>
            <button-with-arrow
              size="l"
              class="token-why-latin-america__button mt-24"
              :to="localePath('/latin-america')"
            >
              {{ $t('welcome.latinAmerica.btn') }}
            </button-with-arrow>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    ButtonWithArrow: () =>
      import('@/components/welcome/elements/ButtonWithArrow.vue'),
  },
  computed: {
    items () {
      return ['br', 'mx', 'ch', 'sv', 've', 'cu', 'pa'].map((flag, index) => ({
        key: flag,
        flag: require(`@/assets/icons/countries/${flag}.png`),
        text: this.$t(`welcome.latinAmerica.items.${index}`),
      }))
    },
  },
}
</script>

<style lang="scss">
.token-why-latin-america {
  &__wrapper {
    background: #a5d4ff;
  }

  &__title {
    grid-area: title;
    align-self: flex-end;
  }
  &__map {
    grid-area: map;

    @include bp(0px, m) {
      text-align: center;
    }

    img {
      width: 100%;
      height: auto;
      max-width: 100%;

      @include bp(m) {
        margin: auto auto auto 0;
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-areas:
      'title'
      'map'
      'items';
    gap: toRem(24px);

    @include bp(m) {
      align-items: center;
      grid-template-columns: 1fr 1.4fr;
      grid-template-areas:
        'map title'
        'map items';
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: toRem(16px);
    grid-area: items;

    @include bp(m) {
      align-self: flex-start;
    }
  }

  &__flag-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: toRem(12px);
    max-width: 44px;
    max-height: 44px;
    height: 44px;
    width: 44px;
    font-size: 29px;
    line-height: 24px;

    img {
      max-width: 68%;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: toRem(44px) 1fr;
    align-items: center;
    gap: toRem(20px);
  }

  &__button {
    @include bp(m) {
      max-width: fit-content;
    }
  }
}
</style>
